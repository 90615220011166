<template>
	<div>
		<div class="change-box">
			<div class="commit-title">{{$route.meta.title}}</div>

			<div class="change-wrap">
				<div class="form-box">


					<div class="form-item">
						<div class="key">旧密码:</div>
						<div class="val">
							<el-input clearable v-model="form.old_password" placeholder="请输入旧密码"></el-input>
						</div>
					</div>
					<div class="form-item">
						<div class="key">新密码:</div>
						<div class="val">
							<el-input clearable v-model="form.password" type="password" placeholder="请填写新密码"></el-input>
						</div>
					</div>
					<div class="form-item">
						<div class="key">确认密码:</div>
						<div class="val">
							<el-input clearable v-model="form.password2" type="password" placeholder="请填写确认密码"></el-input>
						</div>
					</div>

					<div class="form-item" style="margin-top: 40px;">
						<div class="key">
							<div class="tip">
								提示:
							</div>
						</div>
						<div class="val">
							<div class="tip">
								如您忘记密码，请联系管理员获取密码。首次登录或重新获取密码后请及时修改密码。
							</div>
						</div>
					</div>

					<div class="form-item">
						<div class="key"></div>
						<div class="val">
							<div class="btnBox">
								<span class="bg" @click="do_submit()">提交</span>
								<span class="nobg" @click="do_reset()">取消</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					old_password: '',
					password: '',
					password2: '',
				}
			};
		},
		computed: {},
		watch: {},
		created() {

		},
		mounted() {

		},
		methods: {
			do_submit() {
				if (!this.form.old_password) {
					return alertErr('请输入旧密码')
				}
				if (!this.form.password) {
					return alertErr('请输入旧密码')
				}
				if (!this.form.password2) {
					return alertErr('请输入旧密码')
				}
				if (this.form.password != this.form.password2) {
					return alertErr('两次密码不一致')
				}


				this.$api("editPassword", {
					...this.form,
				}, "post").then((res) => {
					alert(res)
					if (res.code == 200) {
						this.form = {
							old_password: '',
							password: '',
							password2: '',
						}
					}
				});
			},
			do_reset() {
				this.form = {
					old_password: '',
					password: '',
					password2: '',
				}

				this.$router.back()
			}
		}
	}
</script>

<style scoped lang="less">
	.change-box {
		min-height: 100vh;
		background-color: #fff;
		border-radius: 8px;
		border: 1px solid #E6E6E6;

		.commit-title {
			padding: 12px 17px;
			font-size: 14px;
			color: #333;
			font-weight: bold;
			border-bottom: 1px solid #EDF0F6;
		}

		.change-wrap {
			width: 900px;
			margin: 0 auto;
			padding-top: 63px;

			.btnBox {
				display: flex;
				align-items: center;
				padding-top: 40px;

				span {
					width: 179px;
					height: 44px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 16px;
					border-radius: 4px 4px 4px 4px;
					margin-right: 20px;
					cursor: pointer;

					&:hover {
						opacity: 0.85;
					}

					&.bg {
						background: #2373C8;
						color: #fff;
					}

					&.nobg {
						color: #4A4A4A;
						border: 1px solid #9D9D9D;
					}
				}
			}

			.form-item {
				display: flex;
				align-items: center;
				margin-bottom: 15px;

				.key {
					width: 80px;
					text-align: right;
					color: #333333;
					font-size: 14px;
					margin-right: 10px;
				}

				.val {
					flex: 1;
				}

			}
		}
	}

	.tip {
		color: coral;
		font-size: 14px;
	}
</style>